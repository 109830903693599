import React from 'react'
import logo from './logo.svg';
import './App.css';

function App() {
  return (

    <div class="center">
      <h1 class="horror-text">coming soon</h1>
    </div>

  );
}

export default App;
